<template>
  <div class="relative w-full h-full flex flex-col items-center justify-center ">
    <div
      v-if="displaySkeleton"
      class="bg-gray-200 shadow-lg skeleton"
    >
      <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <svg fill='none' class="w-12 h-12 animate-spin text-gray-500" viewBox="0 0 32 32" xmlns='http://www.w3.org/2000/svg'>
          <path clip-rule='evenodd'
            d='M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z'
            fill='currentColor' fill-rule='evenodd'
          />
        </svg>
      </div>
    </div>

    <img
      v-if="reRenderImage"
      :src="imageUrl"
      :id="idImage"
      loading="lazy"
      :onload="onLoaded"
      :onerror="onError"
      :class="{displayImage: true, 'mx-auto h-auto max-h-32 w-100 shadow-lg rounded' : useDefaultStyle}"
      class="rounded-xl"
    >
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { makeid } from "~/utils/makeid";

export default defineComponent({
  props: {
    imageUrl: {
      type: String,
      default: ''
    },
    useDefaultStyle: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    let state = reactive({
      displaySkeleton: true,
      reRenderImage: true,
      num: 0
    })

    const displayImage = computed(() => {
      return state.displaySkeleton == true ? 'invisible' : 'visible'
    })

    const idImage = computed(() => makeid(8, null))

    const onLoaded = () => {
      state.displaySkeleton = false
    }
    const onError = () => {
      state.reRenderImage = false

      const setTimeoutClear = setTimeout(() => {
        if(state.num === 5) {
          clearTimeout(setTimeoutClear)
          return
        }
        state.reRenderImage = true
        state.num += 1
      }, 1000)
    }

    return {
      ...toRefs(state),
      displayImage,
      idImage,

      // listening
      onLoaded,
      onError
    }
  },
});
</script>

<style scoped>
.content-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
</style>